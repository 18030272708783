import { Button, Icons, Stack, Typography } from '@healthinal/ui';
import { createLink } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

const ButtonLink = createLink(Button);

export function NotFoundView() {
  const { t } = useTranslation();
  return (
    <Stack maxWidth={480} alignItems="center" gap={2} margin="auto">
      <Icons.Search size="lg" />
      <Typography level="h3" textAlign="center">
        {t('error.not-found.title')}
      </Typography>
      <Typography textAlign="center" marginBottom={1}>
        {t('error.not-found.description')}
      </Typography>
      <ButtonLink variant="solid" color="neutral" to="/">
        {t('error.not-found.action')}
      </ButtonLink>
    </Stack>
  );
}
